import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ShiftChangeRequest } from '@app/model/shift-change-request';
import { ShiftDate } from '@app/model/shift-date.model';
import { ShiftRequest } from '@app/model/shift-request';
import { BehaviorSubject, Observable } from 'rxjs';
import { Shift } from '../model/shift.model';
import { ShiftState } from '@app/enum/shift-state.enum';
import { HttpService } from './http.service';
import { TokenStorageService } from './token-storage.service';
import { CalendarDayDate } from '@app/model/calendar-day-date.model';
import { ShiftWorktime } from '@app/model/shift/shift-worktime.model';
import { ShiftDeleteRequest } from '@app/model/shift/shift-delete-request.model';
import Pageable from '@app/model/pageable';
import { environment } from '@env/environment';

const APPROVED_SHIFTS_PAGE_SIZE = 5;
@Injectable({
    providedIn: 'root',
})
export class ShiftService {
    private savedShiftsSubject: BehaviorSubject<ShiftDate[]> = new BehaviorSubject<ShiftDate[]>([]);
    savedShifts$: Observable<ShiftDate[]> = this.savedShiftsSubject.asObservable();
    private shiftsSubject: BehaviorSubject<Shift[]> = new BehaviorSubject<Shift[]>([]);
    shifts$: Observable<Shift[]> = this.shiftsSubject.asObservable();
    private selectedDaysSubject: BehaviorSubject<CalendarDayDate[]> = new BehaviorSubject<CalendarDayDate[]>([]);
    selectedDays$: Observable<CalendarDayDate[]> = this.selectedDaysSubject.asObservable();
    private shiftToEditSubject: BehaviorSubject<ShiftDate> = new BehaviorSubject<ShiftDate>(null);
    shiftToEdit$: Observable<ShiftDate> = this.shiftToEditSubject.asObservable();
    private editModeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    editMode$: Observable<boolean> = this.editModeSubject.asObservable();
    private refreshRequest = new BehaviorSubject<void>(null);
    refreshRequest$ = this.refreshRequest.asObservable();

    reasoningMessage: string = '';

    constructor(
        // tslint:disable-next-line:deprecation
        private httpService: HttpService,
        private tokenStorageService: TokenStorageService,
        private http: HttpClient,
    ) {}

    setReasoningMessage(message: string) {
        this.reasoningMessage = message;
    }

    fetchSavedShifts(): void {
        this.getSavedShiftsByUserId(this.tokenStorageService.getUserId()).subscribe((res) => {
            this.savedShiftsSubject.next(res);
        });
    }

    setSelectedDays(days: CalendarDayDate[]): void {
        this.selectedDaysSubject.next(days);
    }

    setShiftToEdit(shift: ShiftDate): void {
        this.shiftToEditSubject.next(shift);
    }

    setEditMode(edit: boolean): void {
        this.editModeSubject.next(edit);
    }

    setShifts(shifts: Shift[]): void {
        this.shiftsSubject.next(shifts);
    }

    declinedDate(id: number): Observable<ShiftDate[]> {
        return this.httpService.get(`/shifts/${id}/${ShiftState.DECLINED}`);
    }

    acceptDecision(id: number): Observable<Shift> {
        return this.http.patch<Shift>(`${environment.serverUrl}/shifts/accept-shift-decision/${id}`, null);
    }

    getApprovedShiftsInRange(
        page: number,
        range?: { startDate: string; endDate: string },
        searchTerm?: string,
    ): Observable<Pageable<ShiftDate>> {
        const paramObjects = {
            page,
            pageSize: APPROVED_SHIFTS_PAGE_SIZE,
            startDate: null,
            endDate: null,
            searchTerm: null,
        };

        if (range) {
            paramObjects.startDate = range.startDate;
            paramObjects.endDate = range.endDate;
            paramObjects.searchTerm = searchTerm;
        }

        const params = new HttpParams({ fromObject: paramObjects });

        return this.http.get<Pageable<ShiftDate>>(`${environment.serverUrl}/shifts/approved-in-range`, { params });
    }

    getPreviouslyApproved(page: number, searchTerm: string): Observable<Pageable<ShiftDate>> {
        const params = new HttpParams()
            .set('page', page)
            .set('pageSize', APPROVED_SHIFTS_PAGE_SIZE)
            .set('searchTerm', searchTerm);

        return this.http.get<Pageable<ShiftDate>>(`${environment.serverUrl}/shifts/last-approved`, { params });
    }

    getWorktimesBetweenDates(startDate: string, endDate: string): Observable<ShiftWorktime[]> {
        const params = new HttpParams().set('startDate', startDate).set('endDate', endDate);

        return this.httpService.get<ShiftWorktime[]>(
            `/shifts/worktimes-between-dates${params ? '?' + params.toString() : ''}`,
        );
    }

    sendShiftRequest(shifts: ShiftRequest[]): Observable<ShiftRequest[]> {
        return this.httpService.post('/shifts', shifts);
    }

    getShiftById(id: number): Observable<ShiftDate> {
        return this.httpService.get(`/shifts/shift/${id}`);
    }

    getSavedShiftsByUserId(id: number): Observable<ShiftDate[]> {
        return this.httpService.get(`/shifts/${id}`);
    }

    getShiftsToApprove(): Observable<ShiftDate[]> {
        return this.httpService.get(`/shifts/shifts-to-approve`);
    }

    getPendingShiftsByUserId(id: number): Observable<ShiftDate[]> {
        return this.httpService.get(
            `/shifts/${id}/${ShiftState.PENDING},${ShiftState.REQUESTED_DELETE},${ShiftState.TO_BE_EDITED},${ShiftState.CHANGE_REQUESTED}`,
        );
    }

    getApprovedShiftsByUserId(id: number): Observable<ShiftDate[]> {
        return this.httpService.get(`/shifts/${id}/${ShiftState.APPROVED},${ShiftState.APPROVED_DAY_OFF}`);
    }

    requestPendingDelete(shiftDeleteRequest: ShiftDeleteRequest): Observable<any> {
        return this.httpService.post('/shifts/delete', shiftDeleteRequest);
    }

    requestApprovedDelete(shiftId: number): Observable<any> {
        return this.httpService.delete(`/shifts/${shiftId}`);
    }

    updateShift(shift: ShiftDate): Observable<ShiftDate> {
        return this.httpService.patch(`/shifts/update/${shift.id}`, shift);
    }

    updateShiftByUser(shift: ShiftChangeRequest): Observable<ShiftDate> {
        return this.httpService.patch(`/shifts/update-by-user/${shift.id}`, shift) as unknown as Observable<ShiftDate>;
    }

    refresh() {
        this.refreshRequest.next();
    }

    getAllApprovedShftsBySearchTerm(searchTerm: string): Observable<ShiftDate[]> {
        return this.http.get<ShiftDate[]>(`${environment.serverUrl}/shifts/approved-by-search-term/${searchTerm}`);
    }

    getAllPendingShftsBySearchTerm(searchTerm: string): Observable<ShiftDate[]> {
        return this.http.get<ShiftDate[]>(`${environment.serverUrl}/shifts/pending-by-search-term/${searchTerm}`);
    }
}
