import { APP_INITIALIZER, NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AuthInterceptor } from '@interceptor/auth.interceptor';
import { LoadingInterceptor } from '@interceptor/loading.interceptor';
import { ErrorResponseInterceptor } from '@interceptor/error-response.interceptor';
import { LanguageService } from '@service/language.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import initializeApp from './app-initializer';
import { getAuthServiceProviders } from '../config/social.login';

import { SnackbarConfirmationModule } from '@shared/snackbar-confirmation/snackbar-confirmation.module';
import { DialogModule } from '@view/dialogs/dialog.module';
import { DocumentsModule } from './view/documents/documents.module';
import { MatIconRegistry } from '@angular/material/icon';
import { PageTitleModule } from './shared/page-title/page-title.module';
import { TimesheetModule } from './view/timesheet/timesheet.module';
import { DashboardModule } from './view/dashboard/dashboard.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CustomDateAdapter } from './adapter/adapters';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PerformanceChartComponent } from './view/performance/performance-chart/performance-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        DialogModule,
        FormsModule,
        HttpClientModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule, // Used by notification-system.service on the root level
        MatSnackBarModule, // Used by services on root level
        NgbModule,
        ReactiveFormsModule, // Used by form-builder.service on the root level
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        SnackbarConfirmationModule,
        DocumentsModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatNativeDateModule, // Used by single date picker
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            deps: [LanguageService, MatIconRegistry, DomSanitizer],
            useFactory: initializeApp,
            multi: true,
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: getAuthServiceProviders(),
            } as SocialAuthServiceConfig,
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorResponseInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        DatePipe,
        MatDialogModule,
        CookieService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
