<div *ngIf="!isCircle" class="d-flex flex-row align-items-baseline filter">
    <div class="button-container">
        <button
            *ngFor="let element of elements"
            (click)="onSelect(element)"
            [ngClass]="{ active: selectedValue && selectedValue.name === element.name }"
            [attr.disabled]="element.clickable === false ? '' : null"
        >
            {{ element.name | translate }}
        </button>
    </div>
</div>

<div *ngIf="isCircle" class="d-flex">
    <div *ngFor="let element of elements" (click)="onSelect(element)" class="d-flex flex-row align-items-baseline">
        <div
            [ngClass]="{
                circle: true,
                activeColor: selectedValue && selectedValue.name === element.name,
                inactiveColor: selectedValue && selectedValue.name !== element.name
            }"
        >
            <mat-icon class="checkmark" *ngIf="selectedValue && selectedValue.name === element.name">
                done
            </mat-icon>
        </div>

        <button>
            {{ element.name | translate }}
        </button>
    </div>
</div>
