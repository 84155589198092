<div class="duration-input-container">
    <div class="input-label-container">
        <div class="label">{{ label }} {{ required && !hideRequiredAsterisk ? '*' : '' }}</div>
        <div class="time-input-container">
            <div class="time-container" [ngClass]="{ disabled: isDisabled, hasError: hasError || shiftDateFail }" [class.focused]='isHoursFocused'>
                <input
                    matInput
                    type="text"
                    [ngClass]="{
                        'transparent-cursor': transparentCursor,
                        disabled: isDisabled
                    }"
                    [ngModel]="getHoursValue()"
                    (keydown)="onHoursKeyDown($event)"
                    (keyup)="onHoursKeyUp($event)"
                    (blur)="onHourInputBlur(); setHoursFocused(false)"
                    (focus)="onHourInputFocus(); setHoursFocused(true)"
                    [disabled]="isDisabled"
                    #hourInputRef
                />
            </div>
            <div class="separator">:</div>
            <div class="time-container" [ngClass]="{ disabled: isDisabled, hasError: hasError || shiftDateFail }" [class.focused]='isMinutesFocused'>
                <input
                    matInput
                    type="text"
                    [ngClass]="{ 'transparent-cursor': transparentCursor, disabled: isDisabled }"
                    [ngModel]="getMinutesValue()"
                    (keydown)="onMinutesKeyDown($event)"
                    (keyup)="onMinutesKeyUp($event)"
                    (blur)="onMinuteInputBlur(); setMinutesFocused(false)"
                    (focus)="onMinuteInputFocus(); setMinutesFocused(true)"
                    [disabled]="isDisabled"
                    #minuteInputRef
                />
            </div>
        </div>
    </div>
    <div class="inputError">
        <div *ngIf="hasError || shiftDateFail">
            <span class="errorSymbol"><sup>*</sup></span>
            {{ errorMessages.startShiftTime }}
        </div>
    </div>
</div>
