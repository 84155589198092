import { HttpService } from './http.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HolidayRequest } from '@app/model/holiday-request.model';
import HolidayStatus from '@app/model/holiday-status.model';
import Holiday from '@app/model/holiday.model';
import { CalendarDayDate } from '@app/model/calendar-day-date.model';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HolidayState } from '@app/enum/holiday-state.enum';
import { DEFAULT_LOCALE, ExtraHolidayTypeTranslationKeys } from '@app/constants/constants';
import { HolidayResponse } from '@app/model/holiday-response.model';
import { OtherHoliday } from '@app/model/other-holiday.model';
import { NationalHoliday } from '@app/model/national-holiday';
import { ExtraHolidayType } from '@app/enum/extra-holiday-type.enum';
import { TranslateService } from '@ngx-translate/core';
import Pageable from '@app/model/pageable';
import { ApprovedHolidayBox } from '@app/model/holiday/approved-holiday-box.model';
import { HolidayPeriod } from '@app/model/holiday-period.model';
import { DatePipe } from '@angular/common';
import DateRange from '@app/model/date-range';

const INIT_ISO_CODE = 'HU';
const APPROVED_HOLIDAYS_PAGE_SIZE = 5;
@Injectable({
    providedIn: 'root',
})
export class HolidayService {
    @Output()
    private selectedDaysSubject = new BehaviorSubject<CalendarDayDate[]>([]);
    selectedDays$ = this.selectedDaysSubject.asObservable();
    private refreshRequest = new BehaviorSubject<void>(null);
    refreshRequest$ = this.refreshRequest.asObservable();
    private nationalHoldiaysSubject = new BehaviorSubject<NationalHoliday[]>([]);
    nationalHolidays$ = this.nationalHoldiaysSubject.asObservable();
    holidaysOfUser: HolidayStatus[] = [];
    previousYear: number = null;
    previousYearsNationalHolidays: NationalHoliday[] = [];
    reasoningMessage: string = '';
    remainingCoinHoliday: EventEmitter<number> = new EventEmitter<number>();
    remainingPaternityLeave: EventEmitter<number> = new EventEmitter<number>();
    remainingOtherHolidays: EventEmitter<OtherHoliday[]> = new EventEmitter<OtherHoliday[]>();

    constructor(
        private http: HttpClient,
        private httpService: HttpService,
        private tokenStorageService: TokenStorageService,
        private translate: TranslateService,
        private datePipe: DatePipe,
    ) {}

    getHolidays(year: number): void {
        if (this.previousYear != year) {
            this.previousYear = year;
            this.previousYearsNationalHolidays = this.nationalHoldiaysSubject.value;
            this.getNationalHolidays(year).subscribe((res) => {
                this.nationalHoldiaysSubject.next(res);
            });
        }
    }

    async getAllHolidays(): Promise<HolidayStatus[]> {
        this.holidaysOfUser = await this.getAllHolidaysOfUser();
        this.holidaysOfUser = this.holidaysOfUser.map(({ startDay, endDay, state }) => {
            return { startDay, endDay, state };
        });

        return this.holidaysOfUser;
    }

    getNationalHolidays(year: number): Observable<NationalHoliday[]> {
        return this.httpService.get(`/holidays/national-holidays?year=${year}`);
    }

    getNationalHolidaysByDateRange(dateRange: DateRange): Observable<NationalHoliday[]> {
        return this.httpService.get(
            `/holidays/national-holidays-by-range?startDate=${this.datePipe.transform(
                dateRange.from,
                'yyyy-MM-dd',
            )}&endDate=${this.datePipe.transform(dateRange.to, 'yyyy-MM-dd')}`,
        );
    }

    refreshCalendar() {
        this.refreshRequest.next();
    }

    async getAllHolidaysOfUser(): Promise<HolidayStatus[]> {
        const userId: number = this.tokenStorageService.getUserId();
        const response = await this.httpService.get(`/holidays/${userId}`).toPromise();

        this.formatDates(response as HolidayResponse[]);
        return response as HolidayStatus[];
    }

    getAllHolidaysByUserId() {
        const userId: number = this.tokenStorageService.getUserId();
        return this.httpService.get(`/holidays/${userId}`);
    }

    getAllPendingHolidaysByUserId() {
        const userId: number = this.tokenStorageService.getUserId();
        return this.httpService.get(`/holidays/pending-holidays/${userId}`);
    }

    getNumberOfRemainingHolidaysById(): Observable<number> {
        const userId: number = this.tokenStorageService.getUserId();
        return this.httpService.get(`/coworker/remaining/${userId}`);
    }

    isNationalHoliday(date: Date, nationalHolidays: NationalHoliday[] = []): boolean {
        const valuesToCheck = nationalHolidays.length
            ? nationalHolidays
            : this.nationalHoldiaysSubject.value.concat(this.previousYearsNationalHolidays);

        return valuesToCheck.some((nh) => nh.dateIso === this.datePipe.transform(date, 'yyyy-MM-dd') && !nh.workday);
    }

    isWeekendWorkday(date: Date, nationalHolidays: NationalHoliday[] = []): boolean {
        const valuesToCheck = nationalHolidays.length ? nationalHolidays : this.nationalHoldiaysSubject.value;

        return valuesToCheck.some((nh) => nh.dateIso === this.datePipe.transform(date, 'yyyy-MM-dd') && nh.workday);
    }

    getPendingHolidays(): Observable<number> {
        return this.httpService.get('/holidays/pending');
    }

    getPreviouslyApproved(page: number, searchTerm: string): Observable<Pageable<Holiday>> {
        const params = new HttpParams()
            .set('page', page)
            .set('pageSize', APPROVED_HOLIDAYS_PAGE_SIZE)
            .set('searchTerm', searchTerm);

        return this.http.get<Pageable<Holiday>>(`${environment.serverUrl}/holidays/last-approved`, { params });
    }

    getAllApprovedHolidaysInRange(
        page: number,
        startDate: string,
        endDate: string,
        searchTerm: string,
    ): Observable<Pageable<Holiday>> {
        const params = new HttpParams()
            .set('page', page)
            .set('pageSize', APPROVED_HOLIDAYS_PAGE_SIZE)
            .set('startDate', startDate)
            .set('endDate', endDate)
            .set('searchTerm', searchTerm);

        return this.http.get<Pageable<Holiday>>(`${environment.serverUrl}/holidays/approved-holiday-in-range`, {
            params,
        });
    }

    getPendingHolidaysByUserId(id: number): Observable<number> {
        return this.httpService.get('/holidays/pending/' + id);
    }

    getRemainingHolidaysByHolidayType(holidayType: string): Observable<number> {
        return this.httpService.get(`/holidays/remaining?holidayType=${holidayType}`);
    }

    getOtherHolidays() {
        return this.httpService.get('/holidays/remaining/other');
    }

    updateRemaningOtherHolidays(newOtherHolidays: OtherHoliday[]): void {
        this.remainingOtherHolidays.emit(newOtherHolidays);
    }

    updateRemaningHolidaysByType(amount: number, type: string): void {
        switch (type) {
        case 'COIN':
            this.remainingCoinHoliday.emit(amount);
            break;
        case 'PATERNITY':
            this.remainingPaternityLeave.emit(amount);
            break;
        }
    }

    getPendingHolidaysBySearchTerm(searchTerm: string): Observable<HolidayResponse[]> {
        return this.httpService.get(`/holidays/pending-by-search-term/${searchTerm}`);
    }

    getAllApprovedHolidaysBySearchTerm(searchTerm: string): Observable<Holiday[]> {
        return this.httpService.get(`/holidays/approved-by-search-term/${searchTerm}`);
    }

    getAllApprovedHolidaysByUserId(userId?: number): Observable<Holiday[]> {
        if (!userId) {
            userId = this.tokenStorageService.getUserId();
        }

        return this.httpService.get(`/holidays/approved-holidays/${userId}`);
    }

    getApprovedHolidaysByUserId(id: number): Observable<number> {
        return this.httpService.get('/holidays/approved/' + id);
    }

    getApprovedHolidaysForCalendar(startDate: string, endDate: string): Observable<ApprovedHolidayBox[]> {
        return this.httpService.get(`/holidays/approved/calendar?startDate=${startDate}&endDate=${endDate}`);
    }

    getAllHolidaysByStatus(status: string): Observable<HolidayResponse[]> {
        return this.httpService.get(`/holidays/all/${status}`);
    }

    getHolidayById(id: number) {
        return this.httpService.get(`/holidays/single/${id}`);
    }

    updateSelectedDays(newSelectedDays: CalendarDayDate[]) {
        this.selectedDaysSubject.next(newSelectedDays);
    }

    convertToJSDate(dateString: string, separator: string = '.') {
        // YYYY.MM.DD => JS Date
        const parts = dateString.split(separator);

        const year = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1;
        const day = parseInt(parts[2]);

        return new Date(year, month, day);
    }

    formatDate(date: Date, separator: string) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const formattedDate = `${year}${separator}${month}${separator}${day}`;

        return formattedDate;
    }

    sendHolidayRequest(holidayRequest: HolidayRequest): Observable<HolidayRequest> {
        return this.httpService.post('/holidays', holidayRequest);
    }

    updateHoliday(holiday: Holiday): Observable<Holiday> {
        return this.httpService.patch('/holidays/' + holiday.id, holiday);
    }

    requestDelete(holiday: Holiday): Observable<Holiday> {
        return this.httpService.patch('/holidays/request-delete/' + holiday.id, holiday);
    }

    deleteHoliday(id: number) {
        return this.httpService.delete(`/holidays/${id}`);
    }

    setReasoningMessage(message: string) {
        this.reasoningMessage = message;
    }

    isApproved(date: Date | string, holidays: Holiday[]): boolean {
        return this.checkStateBetween(date, holidays, HolidayState.APPROVED);
    }

    requestedDeleteFromApproved(date: Date | string, holidays: Holiday[]): boolean {
        return this.checkStateBetween(date, holidays, HolidayState.REQUESTED_DELETE_FROM_APPROVED);
    }

    isApprovedRequest(date: Date | string, holidays: Holiday[]): boolean {
        return (
            this.checkStateBetween(date, holidays, HolidayState.APPROVED) ||
            this.checkStateBetween(date, holidays, HolidayState.DELETION_APPROVED)
        );
    }

    isDeclined(date: Date | string, holidays: Holiday[]): boolean {
        return this.checkStateBetween(date, holidays, HolidayState.DECLINED);
    }

    isPendingOrRequestedDelete(date: Date | string, holidays: Holiday[]): boolean {
        return (
            this.checkStateBetween(date, holidays, HolidayState.PENDING) ||
            this.checkStateBetween(date, holidays, HolidayState.REQUESTED_DELETE)
        );
    }

    deletionRequestDenied(date: Date | string, holidays: Holiday[]): boolean {
        return this.checkStateBetween(date, holidays, HolidayState.DELETION_REQUEST_DENIED);
    }

    isPending(date: Date | string, holidays: Holiday[]): boolean {
        return this.checkStateBetween(date, holidays, HolidayState.PENDING);
    }

    formatDates(holidays: Holiday[] | HolidayResponse[]): Holiday[] | HolidayResponse[] {
        holidays.forEach((holiday) => {
            holiday.periods.forEach((period) => {
                const localeStartDay = new Date(period.startDay).toLocaleDateString(DEFAULT_LOCALE, {
                    timeZone: holiday.timeZone,
                });
                const localeEndDay = new Date(period.endDay).toLocaleDateString(DEFAULT_LOCALE, {
                    timeZone: holiday.timeZone,
                });
                period.startDay = this.formatDate(new Date(localeStartDay), '-');
                period.endDay = this.formatDate(new Date(localeEndDay), '-');
            });
        });

        return holidays;
    }

    checkStateBetween(date: Date | string, holidays: Holiday[], state: HolidayState): boolean {
        let result = false;
        if (date instanceof Date) {
            const formattedDate: string = this.formatDate(date, '-');
            holidays.forEach((holiday) => {
                holiday.periods.forEach((period) => {
                    if (
                        this.isDateBetween(period.startDay, period.endDay, formattedDate) &&
                        holiday.state === state &&
                        !holiday.isAcceptedByRequester
                    ) {
                        result = true;
                    }
                });
            });
        }

        return result;
    }

    pendingDate(date: Date | string, holidays: Holiday[]): Holiday {
        return (
            this.getDateForState(date, holidays, HolidayState.PENDING) ??
            this.getDateForState(date, holidays, HolidayState.DELETION_REQUEST_DENIED)
        );
    }

    approvedDate(date: Date | string, holidays: Holiday[]): Holiday {
        return this.getDateForState(date, holidays, HolidayState.APPROVED);
    }

    declinedDate(date: Date | string, holidays: Holiday[]): Holiday {
        return this.getDateForState(date, holidays, HolidayState.DECLINED);
    }

    getDateForState(date: Date | string, holidays: Holiday[], state: HolidayState): Holiday {
        let result = null;
        if (date instanceof Date) {
            const formattedDate: string = this.formatDate(date, '-');
            holidays.forEach((holiday) => {
                holiday.periods.forEach((period) => {
                    if (
                        this.isDateBetween(period.startDay.toString(), period.endDay.toString(), formattedDate) &&
                        holiday.state === state
                    ) {
                        result = holiday;
                    }
                });
            });
        }
        return result;
    }

    getHolidayByDateAndState(
        startDate: Date | string,
        holidays: Holiday[],
        state: HolidayState,
        endDate?: Date | string,
    ): Holiday {
        if (!(startDate instanceof Date) || (endDate && !(endDate instanceof Date))) {
            return null;
        }

        const formattedDate: string = this.formatDate(startDate, '-');

        const result = holidays.find(
            (holiday: Holiday) =>
                holiday.state === state &&
                !holiday.isAcceptedByRequester &&
                holiday.periods.some(
                    (period: HolidayPeriod) =>
                        this.isDateBetween(period.startDay.toString(), period.endDay.toString(), formattedDate) &&
                        (!endDate || period.endDay === this.formatDate(endDate as Date, '-')),
                ),
        );

        return result || null;
    }

    isDateBetween(startDate: string, endDate: string, dateToCheck: string): boolean {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        const dateToCheckObj = new Date(dateToCheck);

        return startDateObj <= dateToCheckObj && dateToCheckObj <= endDateObj;
    }

    getExtraHolidayTranslation(holiday: Holiday | HolidayResponse): string {
        if (holiday.holidayType === ExtraHolidayType.OTHER) {
            return holiday.otherName;
        }

        return this.translate.instant(ExtraHolidayTypeTranslationKeys[holiday.holidayType]);
    }

    acceptDecision(holidayId: number): Observable<Holiday> {
        return this.http.patch<Holiday>(`${environment.serverUrl}/holidays/accept-decision/${holidayId}`, null);
    }

    canBeSelected(date: Date, holidays: Holiday[]): boolean {
        return (
            !this.isApproved(date, holidays) &&
            !this.isNationalHoliday(date) &&
            !this.isPendingOrRequestedDelete(date, holidays) &&
            !this.isDeclined(date, holidays) &&
            !this.requestedDeleteFromApproved(date, holidays)
        );
    }
}
