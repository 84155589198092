import { FULL_DAYS_OF_WEEK_TRANSLATIONS } from '@app/constants/constants';
import { ShiftDate } from '@app/model/shift-date.model';
import * as moment from 'moment';

export function formatMonth(date: Date): string {
    return date.toString().split(' ')[1];
}

export function addMonths(date: Date, months: number): Date {
    const day = date.getDate();
    date.setMonth(date.getMonth() + months);
    if (date.getDate() != day) date.setDate(0);
    return date;
}

export function subtractMonths(months: number): Date {
    const today = new Date();
    const day = today.getDate();
    today.setMonth(today.getMonth() - months);
    if (today.getDate() != day) today.setDate(0);
    return today;
}

export function localISOTime(date: Date): string {
    var tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    return new Date(date.getTime() - tzoffset).toISOString().slice(0, -1).split('T')[0];
}

export function isShiftInPast(shift: ShiftDate): boolean {
    return new Date(shift.shiftTimes[0].start) < new Date();
}

export function convertDateToUTC(date: Date): Date {
    const offsetMinutes = date.getTimezoneOffset();
    return new Date(date.getTime() - offsetMinutes * 60 * 1000);
}

export function getWeekdayFromDate(date: Date): string {
    return FULL_DAYS_OF_WEEK_TRANSLATIONS[date.getDay() - 1];
}
export function formatPerformanceDate(date: Date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

export function getWeekdayFromDay(day: number): string {
    return FULL_DAYS_OF_WEEK_TRANSLATIONS[day];
}

export function areDatesEqual(dateOne: Date, dateTwo: Date): boolean {
    return (
        dateOne.getDate() == dateTwo.getDate() &&
        dateOne.getMonth() == dateTwo.getMonth() &&
        dateOne.getFullYear() == dateTwo.getFullYear()
    );
}

export function getWeekNumber(date: Date) {
    const tempDate = moment(date);
    const weekNumber = tempDate.isoWeek();

    return weekNumber;
}
