import { LanguageService } from '@service/language.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export default function initializeApp(
    languageService: LanguageService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
): () => Promise<void> {
    return () =>
        new Promise<void>((resolve, reject) => {
            iconRegistry.addSvgIcon(
                'coworkers',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/coworkers.svg`),
            );
            iconRegistry.addSvgIcon(
                'dashboard',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/dashboard.svg`),
            );
            iconRegistry.addSvgIcon(
                'documents',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/documents.svg`),
            );
            iconRegistry.addSvgIcon(
                'projects',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/projects.svg`),
            );
            iconRegistry.addSvgIcon(
                'settings',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/settings.svg`),
            );
            iconRegistry.addSvgIcon(
                'timesheet',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/timesheet.svg`),
            );
            iconRegistry.addSvgIcon(
                'performance',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/performance.svg`),
            );
            iconRegistry.addSvgIcon(
                'holidays',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/holiday.svg`),
            );
            iconRegistry.addSvgIcon('shifts', sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/shift.svg`));
            iconRegistry.addSvgIcon('bell', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/bell.svg`));
            iconRegistry.addSvgIcon('info', sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/info.svg`));
            iconRegistry.addSvgIcon(
                'hamburger',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/hamburger_icon.svg`),
            );
            iconRegistry.addSvgIcon(
                'menu-opened',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/menu_opened.svg`),
            );
            iconRegistry.addSvgIcon(
                'menu-closed',
                sanitizer.bypassSecurityTrustResourceUrl(`assets/images/menu/menu_closed.svg`),
            );
            languageService.initAppLanguage();
            resolve();
        });
}
